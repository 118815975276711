<template>
	<div class="search">
		<div class="search-input">
			<input type="text" v-model="searchVal" class="input1" placeholder="请输入关键词搜索" ref="InputVal" />
			<p @click="serchKey">搜索</p>
		</div>
		<div class="search-hot">
			<p>热门搜索</p>
			<ul>
				<li v-for="(item, index) in goods" :key="index" @click="searchName(item)">{{ item.search_name }}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		getSearchKeyword
	} from "@/http/api";

	export default {
		data() {
			return {
				goods: [],
				searchVal: "",
				pages: 1,
				recommends: {},
				site_code: ''
			};
		},

		mounted() {
			//  执行判断登录
			this.site_code = this.$LStorage.getItem('curSiteInfo').site_code
			//  获取热门搜索关键词
			this.hostSearch();
		},

		methods: {
			//热门搜索
			async hostSearch() {
				const res = await getSearchKeyword({
					data: {
						site_code: this.site_code,
						search_types: 2
					}
				});
				//  console.log(res);
				this.goods = res.data;
			},

			// 点击搜索
			async serchKey() {
				if (this.searchVal !== "") {
					this.$router.push({
						path: "/search/info?key=" + this.searchVal
					});
				} else {
					this.$toast("请输入关键词搜索");
				}
			},

			//点击赋值
			async searchName(i) {
				this.searchVal = i.search_name;
				this.serchKey();
			}
		},
		created() {}
	};
</script>


<style lang="scss" scoped>
	.search {
		width: 100%;
		height: 100%;

		.details-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-sizing: border-box;
			font-size: 0.38rem;
			background: #f4f4f4;
		}

		.search-input {
			// width: 100%;
			// height: 1.12rem;
			border-bottom: 1px solid #e7e7e7;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.26rem 0.24rem;

			.input1 {
				width: calc(100% - 0.8rem);
				height: 0.65rem;
				line-height: 0.65rem;
				background: #f2f2f2;
				border-radius: 0.33rem;
				font-size: 0.28rem;
				color: #b3b3b3;
				padding-left: 0.77rem;
				box-sizing: border-box;
				background-image: url("../../assets/serch.png");
				background-repeat: no-repeat;
				background-size: 0.25rem 0.25rem;
				background-position: 0.3rem center;
			}

			p {
				font-size: 0.3rem;
				width: 0.8rem;
				text-align: right;
			}
		}

		.search-hot {
			margin: 0.56rem 0.24rem 0;

			p {
				font-size: 0.3rem;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				font-size: 0.23rem;
				margin-top: 0.31rem;

				li {
					width: 1.45rem;
					height: 0.65rem;
					border-radius: 0.33rem;
					text-align: center;
					line-height: 0.65rem;
					background: #f5f5f5;
					color: #585858;
					margin-right: 0.2rem;
					margin-bottom: 0.2rem;
				}
			}
		}
	}
</style>
